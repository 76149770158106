import React from "react";
import "./MilkyWay.css";

const MilkyWay = () => {
  return (
    <div className="background-image">
      <React.Fragment>
        <div className="hero-wrapper">
          <h1 className="signup-txt" style={{ width: "60%" }}>
            Download MilkyWay777 for Free & Play Exciting Online Casino Games!
          </h1>
        </div>
        <div className="play-button-warpper">
          <button
            className="play-button"
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=61564151433682",
                "_blank"
              )
            }
          >
            <b className="play-now">Join Now</b>
          </button>
        </div>
        <div className="now-play">
          NOW PLAY DIRECTLY WITH US! On PC and Mobile
        </div>

        <div className="banner-container-three">
          <div className="hero-title">
            <div className="looking-for-an">
              Looking for an exciting gaming experience? Play{" "}
              <b>Milky Way Casino Online</b> with <b>Milkyway 777 Casino</b> and
              enjoy thrilling <b>fish games and reels</b>! Get started with{" "}
              <b>free play, bonuses, and community prizes</b>, giving you
              endless ways to win big. Download <b>Milky Way Casino777</b> today
              and <b>play casino online</b>
              anytime, anywhere, on any device. Don’t miss out—
              <b>download casino online</b> now and join the fun!
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default MilkyWay;
