import React from "react";
import "./Description.css";
console.log("Im here");
const Description = () => {
  return (
    <React.Fragment>
      <div className="why-choose-div">
        <div className="why-choose-container">
          <h2 className="now-play">
            Why Choose MilkyWay777 Online Casino Gaming?
          </h2>
          <div className="why-choose-description">
            <p>
              Are you trying to find a thrilling online gaming experience? You
              only need to check out Milkyway's 777 casino, fish game, and reels
              app! You will always have opportunities to win big thanks to free
              play, bonuses, community prizes, and more.
            </p>
            <p>
              Additionally, our app allows you to play and download casino games
              online on any device, anytime, and from any location. Experience
              the excitement of the game 24/7 by becoming a member of our family
              now! Play casino and other games online in the comfort of their
              own home.
            </p>
            <p>The key aspects that make us stand out are:</p>
          </div>
          {/* Container starts */}
          <div className="flex-container">
            <div className="flex-item">
              <div className="item-title">Wide Variety of Games</div>
              <div className="item-description">
                Choose what you want to enjoy from the ocean of choices.
              </div>
            </div>
            <div className="flex-item">
              <div className="item-title">Location Compatibility</div>
              <div className="item-description">
                Easy, seamless access from the comfort of your mobile, computer,
                or tablet.
              </div>
            </div>
            <div className="flex-item">
              <div className="item-title">User-Friendly Interface</div>
              <div className="item-description">
                Our platform was created with enthusiastic users like you in
                mind. It offers easy navigation and secure transactions.
              </div>
            </div>
            <div className="flex-item">
              <div className="item-title">Customer Support</div>
              <div className="item-description">
                Milky Way is known for unmatched customer service, instant
                cash-in and cash-outs, and 24/7 support.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Description;
