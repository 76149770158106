import React from "react";
import MilkyWay from "../components/MilkyWay";
import DownloadMilkyWay from "../components/DownloadMilkyWay";
import Cards from "../components/Cards";
import Description from "../components/Description";
import ExistingFeatures from "../components/ExistingFeatures";
import CopyRight from "../components/CopyRight";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Download Milky Way 777 Casino Online to Play Free Games</title>
        <meta
          name="description"
          content="Download Milky Way 777 Casino online and enjoy a variety of free games, including slots, card games, and more. Play anytime for fun and excitement!"
        />
        <link rel="canonical" href="https://milkyway777slots.com" />
        {/* OG Tags */}
        <meta property="og:title" content="Milky Way 777 Slots" />
        <meta property="og:site_name" content="Milky Way 777 Slots/" />
        <meta property="og:url" content="https://milkyway777slots.com" />
        <meta
          property="og:description"
          content="Download Your Favorite Milky Way App Now and Enjoy a Wide Range of Exciting
        Milkyway Slot Games! Get a 200% Bonus on Your First Deposit. With Our App, You Can Play on Any Device, Anytime,
        Anywhere."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://milkyway777slots.com/assets/milkyway-logo.png"
        />
      </Helmet>
      <MilkyWay />
      <Description />
      <ExistingFeatures />
      <Cards />
      <DownloadMilkyWay />
      <CopyRight />
    </React.Fragment>
  );
};

export default Home;
