// src/data/blogData.js

import Blog_1 from "../components/Blogs/Blog_1";
import Blog_2 from "../components/Blogs/Blog_2";
import Blog_3 from "../components/Blogs/Blog_3";
import Blog_4 from "../components/Blogs/Blog_4";

let blogItems = [
  {
    id: "1",
    slug: "free-slot-games-for-android-phones",
    image: "/assets/blogs/blog-one.webp",

    metaTitle: "How to Choose the Best Free Slot Game for Android Phone Users?",
    title: "How to Choose the Best Free Slot Game for Android Phone Users?",
    content: "Content of blog 1",
    metaDescription:
      "Here are some things to look for before signing up for the slot games for Android! From top-rated apps, enjoying bonuses, to experiencing fun gaming.",
    canonical:
      "https://milkyway777slots.com/blog/free-slot-games-for-android-phones",

    ogTitle: "How to Choose the Best Free Slot Game for Android Phone Users?",
    ogDescription:
      "Here are some things to look for before signing up for the slot games for Android! From top-rated apps, enjoying bonuses, to experiencing fun gaming.",
    ogImage: "https://milkyway777slots.com/assets/blogs/blog-one.webp",
    component: Blog_1,
    sharingUrl:
      "https://milkyway777slots.com/blog/free-slot-games-for-android-phones",
    date: "2024-11-25",
  },
  {
    id: "2",
    slug: "online-casino-gaming",
    image: "/assets/blogs/blog-two.png",

    metaTitle: "How Will AI Change the Landscape of Online Casino Gaming?",
    title: "How Will AI Change the Landscape of Online Casino Gaming?",
    content: "Content of blog 2",
    metaDescription:
      "AI can suggest downloading casinos online and popular casino games that align with your interests, introducing you to new titles you might enjoy. Read more!",
    canonical: "https://milkyway777slots.com/blog/online-casino-gaming",

    ogTitle: "How Will AI Change the Landscape of Online Casino Gaming?",
    ogDescription:
      "AI can suggest downloading casinos online and popular casino games that align with your interests, introducing you to new titles you might enjoy. Read more!",
    ogImage: "https://milkyway777slots.com/assets/blogs/blog-two.png",
    component: Blog_2,
    sharingUrl: "https://milkyway777slots.com/blog/online-casino-gaming",
    date: "2025-02-14",
  },
  {
    id: "3",
    slug: "top-5-online-casinos-download-fast-gameplay",
    image: "/assets/blogs/blog-three.png",
    metaTitle:
      "Top 5 Online Casinos to Download for Fast Gameplay | MilkyWay777Slots",
    title:
      "Top 5 Online Casinos to Download for Fast Gameplay | MilkyWay777Slots",
    content: "Content of blog 2",
    metaDescription:
      "Discover the top 5 online casinos to download for fast gameplay. Enjoy smooth performance, quick load times, and exciting casino games with trusted platforms like MilkyWay777Slots. Download now for seamless gaming!",
    canonical:
      "https://milkyway777slots.com/blog/top-5-online-casinos-download-fast-gameplay",

    ogTitle:
      "Top 5 Online Casinos to Download for Fast Gameplay | MilkyWay777Slots",
    ogDescription:
      "Discover the top 5 online casinos to download for fast gameplay. Enjoy smooth performance, quick load times, and exciting casino games with trusted platforms like MilkyWay777Slots. Download now for seamless gaming!",
    ogImage: "https://milkyway777slots.com/assets/blogs/blog-three.png",
    component: Blog_3,
    sharingUrl:
      "https://milkyway777slots.com/blog/top-5-online-casinos-download-fast-gameplay",
    date: "2025-03-17",
  },
  {
    id: "4",
    slug: "players-guide-for-understanding-the-online-casino-experience",
    image: "/assets/blogs/blog-four.png",
    metaTitle:
      "A Player’s Guide for Understanding the Online Casino Experience",
    title: "A Player’s Guide for Understanding the Online Casino Experience",
    content: "Content of blog 2",
    metaDescription:
      "Understanding and Enhancing the Online Casino Player Experience can dramatically shift how you play, perceive, and win. Here’s how it works",
    canonical:
      "https://milkyway777slots.com/blog/players-guide-for-understanding-the-online-casino-experience",

    ogTitle: "A Player’s Guide for Understanding the Online Casino Experience",
    ogDescription:
      "Understanding and Enhancing the Online Casino Player Experience can dramatically shift how you play, perceive, and win. Here’s how it works",
    ogImage: "https://milkyway777slots.com/assets/blogs/blog-four.png",
    component: Blog_4,
    sharingUrl:
      "https://milkyway777slots.com/blog/players-guide-for-understanding-the-online-casino-experience",
    date: "2025-03-24",
  },
  // Add more blogs as needed
];

let blogData = blogItems.sort((a, b) => new Date(b.date) - new Date(a.date));
export default blogData;
