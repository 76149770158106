import React from "react";
import "./ExistingFeatures.css";

const ExistingFeatures = () => {
  return (
    <React.Fragment>
      <div className="bg-image">
        <div className="features-container">
          <h2 className="features-heading">
            Get Exclusive Features with the MilkyWay777 App
          </h2>
          <p className="features-description">
            Milkyway provides a 200% bonus on the first deposit. Here are the
            key features of the Milky Way app:
          </p>
          <ul className="feature-list">
            <li className="feature-item">Free-to-Play Games</li>
            <li className="feature-item">
              Large Game Selection with Mobile Compatibility
            </li>
            <li className="feature-item">Rewards and Bonuses</li>
            <li className="feature-item">Community and Social Features</li>
            <li className="feature-item">Secure Platform</li>
          </ul>
        </div>
      </div>
      <section className="official-facebook">
        <div className="container">
          <div className="official-head">
            <h2>OFFICIAL FACEBOOK PAGES</h2>
            <p>Please contact our official Partners for account creation</p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="common-facebook">
                <div>
                  <h3>VIP GAMING</h3>
                  <p>
                    VIP Gaming is known for unmatched customer service, instant
                    cash-in and cash-outs and 24/7 support. Join them and
                    experience the thrill of online games.
                  </p>
                </div>
                <div className="feature-link-wraper">
                  <a
                    href="https://www.facebook.com/people/VIP-Gaming/61551686566639/?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <h4>Join Now</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="common-facebook">
                <div>
                  <h3>Milkyway</h3>
                  <p>
                    Join Milkyway to receive 200% bonus on the first deposit.
                  </p>
                </div>

                <div className="feature-link-wraper">
                  <a
                    href="https://www.facebook.com/people/MilkyWay-Online/61564151433682/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <h4>Join Now</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ExistingFeatures;
